<template>
    <el-dialog :title="title" :visible.sync="isShow" :before-close="onClose" width="720px" @opened="onOpened">
        <el-checkbox
            class="checkbox_item"
            v-for="staff in staffList"
            v-model="staffMap[`staff${staff.id}`]"
            :key="staff.id"
            @change="onCheckChange(staff)"
        >
            {{ staff.name }}
        </el-checkbox>
    </el-dialog>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi.js'
import { getProjectStaff, addProjectStaff, delProjectStaff } from '@/js/api/commonApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',

            staffList: [],
            staffMap: null,
        }
    },
    methods: {
        onOpened() {
            this.title = '关联员工'
            if (this.activeItem) {
                this.title = `${this.activeItem.projectName} - 关联员工`
            }

            this.getStaffListApi()
        },
        onClose() {
            this.title = ''
            this.staffList = []
            this.staffMap = null
            this.$emit('onClose')
        },

        onCheckChange(staff) {
            if (this.staffMap[`staff${staff.id}`]) {
                addProjectStaff({
                    projectNo: this.activeItem.projectNo,
                    staffId: staff.id,
                }).then(() => {
                    this.$message.success('编辑成功')
                })
            } else {
                delProjectStaff({
                    projectNo: this.activeItem.projectNo,
                    staffId: staff.id,
                }).then(() => {
                    this.$message.success('编辑成功')
                })
            }
        },

        getProjectStaffApi() {
            getProjectStaff({ projectNos: [this.activeItem.projectNo] }).then((res) => {
                res.data.forEach((i) => {
                    this.staffMap[`staff${i.staffId}`] = true
                })
            })
        },
        getStaffListApi() {
            getStaffList({ page: 1, size: 1000, showPage: false }).then((res) => {
                this.staffList = res.data
                const _map = {}
                res.data.forEach((item) => {
                    _map[`staff${item.id}`] = false
                })
                this.staffMap = _map
                this.getProjectStaffApi()
            })
        },
    },
}
</script>
<style lang="less" scoped>
.checkbox_item {
    min-width: 140px;
    margin-bottom: 6px;
}
</style>
