<template>
    <div class="page_container">
        <div class="query_header">
            <div class="opt_container">
                <el-button class="float_left_btn" type="primary" plain @click="onShowEditProject(null)">
                    新建项目
                </el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="seq" width="80">
                <template slot="header">
                    <el-tooltip class="item" effect="dark" content="列表顺序按照从小到大排列" placement="top">
                        <span>排序 <i class="el-icon-warning" /></span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="projectName" label="项目名称" />
            <el-table-column header-align="center" prop="remark" label="备注" />
            <el-table-column align="center" width="80" label="操作">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link type="primary" @click="onShowStaffDialog(scope.row)">关联员工</el-link>
                        <el-link type="primary" @click="onShowEditProject(scope.row)">编辑项目</el-link>
                        <el-popconfirm title="确定删除吗？" @confirm="onDelProject(scope.row)">
                            <el-link slot="reference" type="danger">删除项目</el-link>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <EditProject :isShow="isShowEditProject" :activeItem="activeItem" @onClose="onCloseEditProject" />
        <EditBindStaff :isShow="isShowEditBindStaff" :activeItem="activeItem" @onClose="onCloseEditBindStaff" />
        <StaffDialog :isShow="isShowStaffDialog" :activeItem="activeItem" @onClose="onCloseStaffDialog" />
    </div>
</template>
<script>
import EditBindStaff from './components/projectList/EditBindStaff.vue'
import EditProject from './components/projectList/EditProject.vue'
import StaffDialog from './components/projectList/StaffDialog.vue'
import { getProjectList, delProject } from '@/js/api/commonApi.js'

export default {
    components: { EditBindStaff, EditProject, StaffDialog },
    data() {
        return {
            tableData: [],

            activeItem: null,
            isShowEditProject: false,
            isShowEditBindStaff: false,
            isShowStaffDialog: false,
        }
    },
    created() {
        this.getProjectListApi()
    },
    methods: {
        onShowEditProject(item) {
            this.activeItem = item
            this.isShowEditProject = true
        },
        onCloseEditProject(state) {
            this.activeItem = null
            this.isShowEditProject = false
            if (state) this.getProjectListApi()
        },
        onDelProject(item) {
            delProject({ projectNo: item.projectNo }).then(() => {
                this.$message.success('删除成功')
                this.getProjectListApi()
            })
        },
        // 关联员工账号
        onShowEditBindStaff(item) {
            this.activeItem = item
            this.isShowEditBindStaff = true
        },
        onCloseEditBindStaff() {
            this.activeItem = null
            this.isShowEditBindStaff = false
        },

        // 关联员工
        onShowStaffDialog(item) {
            this.activeItem = item
            this.isShowStaffDialog = true
        },
        onCloseStaffDialog() {
            this.activeItem = null
            this.isShowStaffDialog = false
        },

        getProjectListApi() {
            getProjectList({ orderBy: '+id' }).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>
