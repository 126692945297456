<template>
    <el-drawer
        class="custom_drawer"
        :visible.sync="isShow"
        :title="title"
        size="640px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <div class="group" v-for="g in groupList" :key="g.id">
            <div class="group_name">
                <el-checkbox :indeterminate="g.indeterminate" v-model="g.checkAll" @change="onCheckAllChange(g)">
                    全选
                </el-checkbox>
                <span class="name">{{ g.name }}</span>
            </div>
            <div class="content">
                <el-checkbox-group v-model="activeStaff" @change="onStaffChange(g)">
                    <el-checkbox v-for="s in g.children" :key="s.id" :label="s.id">
                        <StaffName :staff="s" />
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="drawer_footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi.js'
import { getProjectStaff } from '@/js/api/commonApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',

            groupList: [],
            activeStaff: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '关联账号'
            if (this.activeItem) {
                this.title = `${this.activeItem.projectName} - 关联账号`
            }

            this.getProjectStaffApi()
            this.getStaffListApi()
        },
        onClose() {
            this.$emit('onClose')
        },

        onCheckAllChange(group) {
            if (group.checkAll) {
                group.children.forEach((s) => {
                    if (!this.activeStaff.includes(s.id)) {
                        this.activeStaff.push(s.id)
                    }
                })
            } else {
                group.children.forEach((s) => {
                    const _index = this.activeStaff.indexOf(s.id)
                    if (_index !== -1) {
                        this.activeStaff.splice(_index, 1)
                    }
                })
            }
            console.log(this.activeStaff)
        },
        onStaffChange(group) {
            let _checkNum = 0
            group.children.forEach((s) => {
                if (this.activeStaff.includes(s.id)) {
                    _checkNum += 1
                }
            })
            group.indeterminate = _checkNum > 0 && _checkNum < group.children.length
            group.checkAll = _checkNum === group.children.length
        },

        onSubmit() {
            if (!this.activeStaff.length === 0) {
                return this.$message.warning('请选择账号')
            }
            // saveProjectStaff({ projectNos: [this.activeItem.projectNo], staffIds: this.activeStaff })
        },

        getProjectStaffApi() {
            getProjectStaff({ projectNos: [this.activeItem.projectNo] }).then((res) => {
                console.log(res)
            })
        },
        getStaffListApi() {
            getStaffList({}).then((res) => {
                // 按照组排序
                const _groupMap = {
                    '-1': {
                        name: '-',
                        children: [],
                        seq: -1,
                        id: -1,
                        indeterminate: false,
                        checkAll: false,
                    },
                }
                res.data.forEach((item) => {
                    if (item.group) {
                        if (!_groupMap[item.group.id]) {
                            _groupMap[item.group.id] = {
                                ...item.group,
                                children: [],
                                indeterminate: false,
                                checkAll: false,
                            }
                        }
                        _groupMap[item.group.id].children.push(item)
                    } else {
                        _groupMap['-1'].children.push(item)
                    }
                })
                this.groupList = Object.values(_groupMap)
                this.groupList.sort((a, b) => a.seq - b.seq)
                console.log(this.groupList)
            })
        },
    },
}
</script>
<style lang="less" scoped>
.group {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #efefef;
    .group_name {
        margin-bottom: 16px;
        .name {
            margin-left: 16px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}
</style>
